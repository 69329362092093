import { FC,  useEffect, useState } from "react";
import { Stack, Typography, MenuItem, Grid, TextField } from "@mui/material";
import ProductForm from "./ProductForm";
import State from "../../core/state";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { FormField } from "../../core/util";
import {isEqual} from "lodash";
import { PolicyDate } from "../../core/policy_attributes";

const titleCase = (s: string) => s.toLowerCase().split("_")
.filter(x => x.length > 0)
.map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
.join(" ");

interface CoveredPerson {
  first_name?: string;
  last_name?: string;
  birth_date?: PolicyDate;
  state?: string;
  street?: string;
}

const MedSaverForm: FC<ProductForm> = ({quote, onChange}: ProductForm) => {

  const [participants, setParticipants] = useState<any[]>();
  const [valid, setValid] = useState(true);

  useEffect(() => {
    let updatedParticipants: any[] = [];
    quote.policy_attributes.covered_persons.map((c: any, i: number) => {
      let participantDetails: {[k: string]: FormField} = {};

      
      ['first_name', 'last_name', 'birth_date', 'state', 'street'].map(k => {
        let value = k in c ? c[k] : undefined;
        participantDetails[k] = {
          value: value,
          error: value == undefined ? true : value.length == 0,
          errorMsg: 'This field is required'
        }
      });

      updatedParticipants[i] = participantDetails;
    });
    setParticipants(updatedParticipants);
  }, [quote]);

  useEffect(() => {
    handleChange();
  }, [participants, valid]);

  const handleChange = () => {
    let coveredPersons: any[] = [];
    
    if (participants) {
      participants.forEach((v) => {
        let coveredPerson: {[k: string]: any} = {};

        Object.keys(v).map(k => {
          coveredPerson[k] = v[k].value;
        })

        coveredPersons.push(coveredPerson);
      });

      if (!isEqual(coveredPersons, quote.policy_attributes.covered_persons) && onChange) {
        onChange({
          valid: valid,
          policyAttributes: {
            ...quote.policy_attributes, 
            covered_persons: coveredPersons
          }
        });
      }
    }
  }

  const setParticipantAttribute = (i: number, k: string, v: any) => {
    let updatedParticipants = participants ? [...participants] : [];
    let error = v == undefined ? true : v.length == 0;

    updatedParticipants[i][k] = {
      ...updatedParticipants[i][k],
      value: v,
      error: error
    }

    if (valid && error) {
      setValid(false);
    } else if (!valid && !error) {
      setValid(true);
    }

    setParticipants(updatedParticipants);
  }

  return (
    <Stack
      width="100%"
      sx={{
        mt: 1,
        mb: 6
      }}
    >
      <TextField label="Activity" value={quote.policy_attributes.coverage_type ? titleCase(quote.policy_attributes.coverage_type) : 'Not Available'} disabled fullWidth />
      <Typography textAlign={{xs: 'left', md: 'center'}}  variant="h6" mt={4} mb={3}>Participant Information</Typography>
      {participants?.map((p: any, i: number) => (
        <Grid container spacing={2} key={i}>
          <Grid item xs={6}>
            <TextField
              required
              label="First Name"
              fullWidth
              error={p.first_name.error}
              helperText={p.first_name.error && p.first_name.errorMsg}
              value={p.first_name.value || ''}
              onChange={(e) => setParticipantAttribute(i, 'first_name', e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              fullWidth
              required
              error={p.last_name.error}
              helperText={p.last_name.error && p.last_name.errorMsg}
              value={p.last_name.value || ''}
              onChange={(e) => setParticipantAttribute(i, 'last_name', e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Street"
              required
              fullWidth
              value={p.street.value || ''}
              error={p.street.error}
              helperText={p.street.error && p.street.errorMsg}
              onChange={(e) => setParticipantAttribute(i, 'street', e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              select
              required
              label="State"
              inputProps={{ name: "St" }}
              fullWidth
              defaultValue={State.NONE}
              value={p.state.value || ''}
              disabled
              error={p.state.error}
              helperText={p.state.error && p.state.errorMsg}
              onChange={(e) => setParticipantAttribute(i, 'state', e.target.value)}
            >
              {Object.values(State).map((st) => (
                <MenuItem value={st} key={st}>
                  {st}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                sx={{width: '100%'}} 
                disableFuture 
                label="Birth Date *" 
                value={p.birth_date.value ? dayjs(p.birth_date.value) : dayjs()} 
                onChange={(value) => setParticipantAttribute(i, 'birth_date', value)}
                slotProps={{
                  textField: {
                    error: p.birth_date.error,
                    helperText: p.birth_date.error && p.birth_date.errorMsg,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
}

export default MedSaverForm;