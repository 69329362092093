import { Box, Container, Link, Stack, Typography } from "@mui/material";


export default function Footer() {
  return (
    <Box bgcolor="#f2f2f2">
      <Container maxWidth="lg" sx={{paddingY: 2}}>
        <Stack sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} direction={{xs: 'column', md: 'row'}} spacing={1}>
          <Typography variant="body2">
            Copyright © 2023 Vertical Insure, Inc. 
          </Typography>
          <Typography variant="body2">
            <Link href="https://www.verticalinsure.com/about" color="info.main">About Us</Link> | <Link href="https://www.verticalinsure.com/legal/terms-and-conditions" color="info.main">Terms of Use</Link> | <Link href="https://www.verticalinsure.com/legal/privacy-policy" color="info.main">Privacy Policy</Link>
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}