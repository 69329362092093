export class QuoteNotAvailableError extends Error {}
export class QuoteDatesInvalidError extends Error {}

export class PurchaseFailedError extends Error {}
export class PaymentFailedError extends Error {}

export class PartnerLogoNotAvailableError extends Error {}

export class AuthenticationError extends Error {}
export class ValidationError extends Error {}
