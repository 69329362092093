import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { CssBaseline } from "@mui/material";

import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";
import SuccessfulPurchase from "./pages/SuccessfulPurchase";

import ExpiredQuote from "./pages/ExpiredQuote";
import "./styles/index.css";
import { datadogRum } from '@datadog/browser-rum';

if (import.meta.env.PROD) {
  datadogRum.init({
      applicationId: 'd2267a6d-b4c0-4424-851b-ae6ea8a1e33f',
      clientToken: 'pub75e1cb4e8689da6918a158cdb665b670',
      site: 'datadoghq.com',
      service:'hosted-conversion',
      env:'prod',
      version: import.meta.env.VITE_APP_VERSION,
      sessionSampleRate:100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input',
      allowedTracingUrls: [(url) => url.startsWith("https://api.verticalinsure.com")]
  });  
  datadogRum.startSessionReplayRecording();
} 

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route index path="/:product/:quoteId" element={<Checkout />}></Route>
        <Route
          index
          path="/successful-purchase"
          element={<SuccessfulPurchase />}
        ></Route>
        <Route index path="/expired-quote" element={<ExpiredQuote />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
