import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef, DOMAttributes, RefAttributes, FC } from "react";
import {
  TextField,
  Box,
  Typography,
  MenuItem,
  Grid,
  Stack,
  Container,
  FormControl,
  useTheme,
  ThemeProvider,
  Theme,
  Alert
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import { QuoteResponse } from "../core/quote";
import { Id, ProductType, Token, FormField } from "../core/util";
import { PaymentFailedError, QuoteDatesInvalidError, ValidationError } from "../core/errors";
import { JustifiPaymentMethodData } from "../core/justifi";
import { CreatePaymentMethodResponse } from "@justifi/webcomponents/dist/types/components/payment-method-form/payment-method-responses"
import State from "../core/state";
import useInsuranceApi from "../api/quote_api";
import usePartnerApi from "../api/partner_api";
import Appbar from "../components/Appbar";
import StockBanner from "../components/Checkout/StockBanner";
import InfoBanner from "../components/Checkout/InfoBanner";
import DoubleDiv from "../components/DoubleDiv";
import Spinner from "../components/Spinner";
import theme from "../styles/theme";
import { defineCustomElement, JustifiCardForm } from "@justifi/webcomponents/dist/components/justifi-card-form"
import PartnerThemeResponse from "../core/partner";
import ProductForm, { ProductFormChangeEvent } from "../components/Forms/ProductForm";
import Footer from "../components/Footer";
import MedSaverForm from "../components/Forms/MedSaverForm";
import EventParticipationCancellationInsuranceForm from "../components/Forms/EventParticipationCancellationInsuranceForm";


defineCustomElement();

type CustomElement<T> = Partial<T & DOMAttributes<T> & RefAttributes<T> & { children: any }>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['justifi-card-form']: CustomElement<JustifiCardForm>;
    }
  }
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});


const formComponents: {[k: string]: FC<ProductForm>;} = {
  'medsaver': MedSaverForm,
  'event-participation-cancellation-insurance': EventParticipationCancellationInsuranceForm
};

const AdditionalProductInfoForm = (props: {product: string, quote: QuoteResponse<any>, onChange: (e: ProductFormChangeEvent) => void}) => {
  const FormComponent = formComponents[props.product];
  return <FormComponent quote={props.quote} onChange={props.onChange}/>;
}

const clientId = {
  test: 'test_RGMDV4FV4BNK4TSPT7DOQVC3P9HKEXTQ',
  live: 'live_VLGC6CAVYP5GHUR4BHSYVOCOK3B0GA7A'
}

const paymentProcessorClientId = {
  test: 'test_hh3GX6nxz0NHWI8xQRoAcFo8xDIM9qZP',
  live: 'live_qrutPmwY10YbjFInxwPh6GQgGagneeDq'
}

export default function Checkout() {
  const { product, quoteId } = useParams();
  const [searchParams] = useSearchParams();
  const [loadedTheme, setLoadedTheme] = useState<Theme>();
  const defaultTheme = useTheme();

  const { getQuote, updateQuote, purchaseQuote } = useInsuranceApi<any>();
  const { getPartnerTheme } = usePartnerApi();

  const [partnerTheme, setPartnerTheme] = useState<PartnerThemeResponse>();
  const [quote, setQuote] = useState<QuoteResponse<any>>();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [premiumChange, setPremiumChange] = useState(false);
  const [paymentProcessorError, setPaymentProcessorError] = useState<string>();
  const authToken = searchParams.get("id");

  const justifiCardFormRef = useRef<JustifiCardForm>(null);
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);

  const additionalProductInfoFormElement = useRef<FC<ProductForm>>(null);

  // These might come from the quote itself, but they can be overridden upon
  // purchase.
  const [firstName, setFirstName] = useState<FormField>({
    value: "",
    error: true,
    errorMsg: "You must enter a first name",
  });
  const [lastName, setLastName] = useState<FormField>({
    value: "",
    error: true,
    errorMsg: "You must enter a last name",
  });
  const [address, setAddress] = useState<FormField>({
    value: "",
    error: true,
    errorMsg: "You must enter an address",
  });
  const [city, setCity] = useState<FormField>({
    value: "",
    error: true,
    errorMsg: "You must enter a city",
  });
  const [st, setSt] = useState<FormField>({
    value: State.NONE,
    error: true,
    errorMsg: "You must enter a state",
  });
  const [zip, setZip] = useState<FormField>({
    value: "",
    error: true,
    errorMsg: "You must enter a zip code",
  });
  const [emailAddress, setEmailAddress] = useState<FormField>({
    value: "",
    error: true,
    errorMsg: "You must enter an email address",
  });

  const [additionalProductInfoFormValid, setAdditionalProductInfoFormValid] = useState(true);

  const navigate = useNavigate();

  function quoteEventDateIsOlderThanToday(quote: QuoteResponse<any>) {
    let comparisonDate = undefined;

    if ('event_start_date' in quote.policy_attributes) {
      comparisonDate = quote.policy_attributes.event_start_date;
    } else if ('coverage_start_date' in quote.policy_attributes) {
      comparisonDate = quote.policy_attributes.coverage_start_date;
    }
    
    if (comparisonDate) {
      return (
        new Date(Date.parse(comparisonDate)) <
        new Date()
      );
    }

    return false;
  }

  async function loadData() {
    try {
      const newQuote = await getQuote(
        product as ProductType,
        quoteId as Id,
        authToken as Token
      );

      if (quoteEventDateIsOlderThanToday(newQuote)) navigate("/expired-quote");

      setQuote(newQuote);

      setFirstName({
        ...firstName,
        value: newQuote.policy_holder.first_name,
        error:
          newQuote.policy_holder.first_name === undefined
            ? true
            : newQuote.policy_holder.first_name.length === 0,
      });
      setLastName({
        ...lastName,
        value: newQuote.policy_holder.last_name,
        error:
          newQuote.policy_holder.last_name === undefined
            ? true
            : newQuote.policy_holder.last_name.length === 0,
      });
      setAddress({
        ...address,
        value: newQuote.policy_holder.street,
        error:
          newQuote.policy_holder.street === undefined
            ? true
            : newQuote.policy_holder.street.length === 0,
      });
      setCity({
        ...city,
        value: newQuote.policy_holder.city,
        error:
          newQuote.policy_holder.city === undefined
            ? true
            : newQuote.policy_holder.city.length === 0,
      });
      setSt({
        ...st,
        value: newQuote.policy_holder.state as State,
        error:
          newQuote.policy_holder.state === undefined
            ? true
            : newQuote.policy_holder.state.length === 0,
      });
      setZip({
        ...zip,
        value: newQuote.policy_holder.postal_code,
        error:
          newQuote.policy_holder.postal_code === undefined
            ? true
            : newQuote.policy_holder.postal_code.length === 0,
      });
      setEmailAddress({
        ...emailAddress,
        value: newQuote.policy_holder.email_address,
        error:
          newQuote.policy_holder.email_address === undefined
            ? true
            : newQuote.policy_holder.email_address.length === 0,
      });

      try {
        const partnerTheme = await getPartnerTheme(
          newQuote!.partner.id,
          authToken as Token
        );
        setPartnerTheme(partnerTheme);
        setLoadedTheme(theme(partnerTheme));

      } catch {
        setLoadedTheme(theme());
      }
    } catch (error) {
      if (error instanceof QuoteDatesInvalidError) navigate("/expired-quote");
      else navigate("/");

      throw error;
    } finally {
      setLoading(false);
      setReady(true);
    }
  }

  useEffect(() => {
    setLoading(true);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = async () => {
    const currentJustifiCardForm = justifiCardFormRef.current;

    if (!zip.value) throw new ValidationError("Invalid billing zip code.");

    const validationData = await currentJustifiCardForm!.validate();

    return !(
      firstName.error &&
      lastName.error &&
      address.error &&
      st.error &&
      zip.error
    ) && validationData.isValid && additionalProductInfoFormValid;
  }

  const handleAdditionalProductFormDataChange = (e: ProductFormChangeEvent) => {
    setAdditionalProductInfoFormValid(e.valid);
    let updatedQuote = {...quote} as QuoteResponse<any>;
    updatedQuote.policy_attributes = e.policyAttributes;
    setQuote(updatedQuote);
  }

  async function handlePurchase() {
    setLoading(true);
    setPaymentProcessorError(undefined);

    try {
      if (await validateForm()) {

        const overriddenQuote = overridenQuoteParamsWithFormParams();

        const updatedQuote = await updateQuote(
          product as ProductType,
          overriddenQuote,
          quote?.is_test ? clientId.test : clientId.live
        );

        if (updatedQuote.premium_amount !== quote?.premium_amount) {
          setLoading(false);
          setPremiumChange(true);
          setQuote(updatedQuote);
          return;
        }

        const paymentMethodResponse = await justifiTokenize();

        if (paymentMethodResponse.error?.code) {
          let errorCode = paymentMethodResponse.error?.code; 

          let errorMessage = "Something went wrong while trying to process your payment. Please try again later.";

          if (errorCode.startsWith("invalid") || errorCode.endsWith("invalid")) {
            let param = errorCode.replace("invalid", "").replaceAll("_", " ").trimEnd();
            errorMessage = `The ${param} provided is not valid.`;
          } else {
            console.error(paymentMethodResponse.error); // Not sure what to do with this error so it will show up in data dog.
          }

          setPaymentProcessorError(errorMessage);
          return;
        }

        if (quote && paymentMethodResponse.id) {

          const newPurchase = await purchaseQuote(
            product as ProductType,
            quoteId as Id,
            authToken as Token,
            updatedQuote,
            paymentMethodResponse.id
          );

          if (newPurchase) {
            navigate("/successful-purchase", {state: {
              partnerTheme: partnerTheme
            }});
          } 
        }
      }
    } catch (error) {
      if (error instanceof PaymentFailedError) {
        setPaymentProcessorError('Your card has been declined.');
      } else {
        setPaymentProcessorError('Something went wrong on our end. Please try again later.');
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }

  function overridenQuoteParamsWithFormParams() {
    return {
      ...quote,
      customer: {
        ...quote!.policy_holder,
        first_name: firstName.value,
        last_name: lastName.value,
        address: address.value,
        city: city.value,
        state: st.value as State,
        postal_code: zip.value,
        email_address: emailAddress.value
      },
    } as QuoteResponse<any>;
  }

  async function justifiTokenize(): Promise<CreatePaymentMethodResponse> {
    const currentJustifiCardForm = justifiCardFormRef.current;

    const paymentMethodData: JustifiPaymentMethodData = {
      name: `${firstName.value} ${lastName.value}`,
      address_postal_code: zip.value,
    };
    if (savePaymentMethod)
      paymentMethodData.email = quote!.policy_holder.email_address;

    const tokenObj: CreatePaymentMethodResponse = await currentJustifiCardForm!.tokenize(
      quote?.is_test ? paymentProcessorClientId.test : paymentProcessorClientId.live,
      paymentMethodData
    );

    return tokenObj;
  }

  return (
    <ThemeProvider theme={loadedTheme || defaultTheme}>
      <Stack flex="1">
        {loading && !ready ? (
          <Spinner />
        ) : (
          <>
            <Appbar partnerTheme={partnerTheme} />
            <Box display={{xs: 'none', md: 'block'}}>
              <StockBanner product={product as ProductType}/>

              {quote && (
                <InfoBanner
                  promotionalDescription={quote!.product!.promotional_description}
                  perils={quote!.product!.perils}
                  legalDisclaimer={quote!.product!.legal_disclaimer}
                />
              )}
            </Box>

            <Box sx={{pt: 4, pb: 2}}>
              <Container id="metadata" maxWidth="sm">
                <Stack alignItems="center">
                  <Typography variant="h4" textAlign="center" fontFamily="Playfair Design">
                    Purchase {quote?.product.friendly_name}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3 }} fontFamily="Lato">
                    Gain peace of mind with a few clicks.
                  </Typography>

                  <DoubleDiv />

                  <Typography
                    variant="body1"
                    sx={{
                      mt: 2,
                      mb: 2,
                      textAlign: "center",
                    }}
                    fontFamily="Lato"
                  >
                    Please fill in the following fields to complete your purchase.
                    Afer completion, you'll receive an email notification with a
                    link to your policy including directions to file a claim and
                    contact information for support.
                  </Typography>
                  {product && quote && 
                    <AdditionalProductInfoForm product={product} quote={quote} onChange={handleAdditionalProductFormDataChange} />
                  }

                  <Typography textAlign={{xs: 'left', md: 'center'}} variant="h6" mb={2} width="100%">Policy Holder Information</Typography>
                  <FormControl sx={{ alignItems: "center" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label="First Name"
                          fullWidth
                          value={firstName.value}
                          error={firstName.error}
                          helperText={firstName.error && firstName.errorMsg}
                          onChange={(e) => {
                            setFirstName({
                              ...firstName,
                              value: e.target.value,
                              error: e.target.value.length === 0,
                            });
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Last Name"
                          required
                          fullWidth
                          value={lastName.value}
                          error={lastName.error}
                          helperText={lastName.error && lastName.errorMsg}
                          onChange={(e) => {
                            setLastName({
                              ...lastName,
                              value: e.target.value,
                              error: e.target.value.length === 0,
                            });
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Email Address"
                          required
                          fullWidth
                          value={emailAddress.value}
                          error={emailAddress.error}
                          helperText={emailAddress.error && emailAddress.errorMsg}
                          onChange={(e) => {
                            setEmailAddress({
                              ...emailAddress,
                              value: e.target.value,
                              error: e.target.value.length === 0,
                            });
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Street"
                          required
                          fullWidth
                          value={address.value}
                          error={address.error}
                          helperText={address.error && address.errorMsg}
                          onChange={(e) => {
                            setAddress({
                              ...address,
                              value: e.target.value,
                              error: e.target.value.length === 0,
                            });
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="City"
                          required
                          fullWidth
                          value={city.value}
                          error={city.error}
                          helperText={city.error && city.errorMsg}
                          onChange={(e) => {
                            setCity({
                              ...city,
                              value: e.target.value,
                              error: e.target.value.length === 0,
                            });
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <TextField
                          select
                          label="State"
                          required
                          inputProps={{ name: "St" }}
                          fullWidth
                          defaultValue={State.NONE}
                          value={st.value}
                          error={st.error}
                          helperText={st.error && st.errorMsg}
                          onChange={(e) => {
                            setSt({
                              ...st,
                              value: e.target.value as State,
                              error: e.target.value.length === 0,
                            });
                          }}
                        >
                          {Object.values(State).map((st) => (
                            <MenuItem value={st} key={st}>
                              {st}
                            </MenuItem>
                          ))}
                        </TextField>
                        
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Zip Code"
                          required
                          fullWidth
                          value={zip.value}
                          error={zip.error}
                          helperText={zip.error && zip.errorMsg}
                          onChange={(e) => {
                            setZip({
                              ...zip,
                              value: e.target.value,
                              error: e.target.value.length === 0,
                            });
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        mt: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontFamily="Playfair Design"
                        variant="h4"
                        sx={{ mb: 0.5 }}
                      >
                        Payment
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <LockIcon fontSize="small" />
                        <Typography variant="subtitle2">
                          SECURE CHECKOUT
                        </Typography>
                      </Box>
                    </Box>

                    {quote && (
                      <Box
                        width="100%"
                        sx={{
                          mt: 1,
                          mb: 3,
                          p: 2,
                          borderRadius: 1,
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold" flex="1">
                          Total amount due
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                          {formatter.format(quote.premium_amount / 100)}
                        </Typography>
                      </Box>
                    )}

                    <justifi-card-form
                      ref={justifiCardFormRef}
                      
                      validation-strategy="onSubmit"
                    ></justifi-card-form>

                    <Typography
                      variant="caption"
                      fontFamily="Lato"
                      sx={{ fontStyle: "italic", fontSize: "0.7rem", backgroundColor: 'transparent', mt: 1 }}
                      dangerouslySetInnerHTML={{ __html: quote?.product.legal_disclaimer ?? "" }}
                    ></Typography>
                    
                    {paymentProcessorError &&
                      <Alert sx={{width: '100%', mt: 2}} severity="error" icon={false}>{paymentProcessorError}</Alert>
                    }

                    <LoadingButton
                      sx={{
                        mt: 2.5,
                        pl: 1.5,
                        pr: 1.5,
                        fontFamily: "Lato",
                        fontSize: "1.05rem",
                        width: {xs: '100%', md: 'auto'}
                      }}
                      loading={loading}
                      disableElevation
                      color="secondary"
                      variant="contained"
                      onClick={handlePurchase}
                    >
                      {premiumChange ? `Pay ${formatter.format(quote?.premium_amount ? quote?.premium_amount / 100 : 0)}` : "Complete Purchase"}
                    </LoadingButton>

                  </FormControl>
                </Stack>
              </Container>
            </Box>
            <Footer />
          </>
        )}
      </Stack>
    </ThemeProvider>
  );
}
