import { Divider } from "@mui/material";

export default function DoubleDiv() {
  return (
    <>
      {[...Array(2)].map((_, i) => (
        <Divider
          key={i}
          sx={{
            borderBottomWidth: 2,
            borderColor: "#000",
            width: "10vw",
            minWidth: "40px",
            maxWidth: "50px",
            mb: 1,
          }}
        />
      ))}
    </>
  );
}
