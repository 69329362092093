import { createTheme, responsiveFontSizes } from "@mui/material";

import "@fontsource/playfair-display";
import "@fontsource/lato";
import PartnerThemeResponse from "../core/partner";

export const primaryColor = "#00263c";
export const secondaryColor = "#ff6b00";

export const errorColor = secondaryColor;
export const errorFontSize = "0.95rem";

const theme = (overrides: PartnerThemeResponse | undefined = undefined) => responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ["sans-serif", "Lato", "Playfair Display"].join(","),
    },
    palette: {
      primary: {
        main: overrides?.color_primary ||  primaryColor,
        contrastText: overrides?.color_primary_contrast_text || "#ffffff",
      },
      secondary: {
        main: overrides?.color_secondary || secondaryColor,
        contrastText: overrides?.color_secondary_constrast_text ||  "#ffffff",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: (opts) => ({ 
            'label': {
              transform: 'none',
            },
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
          })
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: 'none',
            position: 'unset',
            margin: '0 0 0.5rem 0',
            lineHeight: '18px'
          }
        }
      }
    }
  })
);
export default theme;
