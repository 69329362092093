import { Stack, Box, Typography, ThemeProvider, Container } from "@mui/material";
import theme from "../styles/theme";
import Appbar from "../components/Appbar";
import Footer from "../components/Footer";

export default function NotFound() {
  return (
    <ThemeProvider theme={theme()}>
      
        <Appbar />
        <Container sx={{display: 'flex', flex: 1}}>
          <Stack
            sx={{
              flex: 1,
              display: "flex",
              mt: 5
            }}
          >
            <Typography
              variant="h6"
              fontFamily="Lato"
              color="secondary"
              sx={{
                fontSmooth: "always"
              }}
            >
              We couldn't find the page you were looking for. This is either because:
            </Typography>
            <ul>
              <li><Typography fontFamily="Lato" variant="body1">There is an error in the URL entered into your web browser. Please check the URL and try again.</Typography></li>
              <li><Typography fontFamily="Lato" variant="body1">The page you are looking for has been moved or deleted.</Typography></li>
            </ul>
          </Stack>
        </Container>
        <Footer />
    </ThemeProvider>
  );
}
