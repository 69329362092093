import { FC } from "react";
import { Stack, Typography, Divider } from "@mui/material";
import { EPCIMetadata } from "../../core/quote";
import ProductForm from "./ProductForm";

const EventParticipationCancellationInsuranceForm: FC<ProductForm> = ({quote}) => {
  return (
    <Stack
      width="100%"
      sx={{
        mt: 1,
        mb: 4,
        p: 2,
        borderRadius: 1,
        border: "1px solid rgba(0, 0, 0, 0.2)",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: "rgba(0, 0, 0, 0.42)",
          textAlign: "left",
        }}
      >
        Participant
      </Typography>
      <Typography variant="h6">
        {(quote?.metadata as EPCIMetadata)?.participant_name ??
          "Not available"}
      </Typography>
      <Divider
        sx={{
          borderColor: "rgba(0, 0, 0, 0.2)",
          mt: 1,
          mb: 1,
        }}
      />
      <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
        Program
      </Typography>
      <Typography variant="h6">
        {(quote?.metadata as EPCIMetadata)?.event_name ??
          "Not available"}
      </Typography>
    </Stack>
  );
}

export default EventParticipationCancellationInsuranceForm;