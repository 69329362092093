import { Stack, ThemeProvider, Typography } from "@mui/material";
import Appbar from "../components/Appbar";
import theme from "../styles/theme";
import Footer from "../components/Footer";

export default function ExpiredQuote() {
  return (
    <ThemeProvider theme={theme()}>
      <Appbar />
      <Stack
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          p: 4,
        }}
      >
        <Typography variant="h5" fontFamily="Lato" sx={{ mb: 1, textAlign: "center"}}>
          This quote has expired.
        </Typography>
      </Stack>
      <Footer />
    </ThemeProvider>
  );
}
