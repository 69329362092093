import { FC } from "react";

import { Typography, Grid, Container, Box } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { HtmlString } from "../../core/util";

interface InfoBannerProps {
  promotionalDescription?: HtmlString;
  perils?: string[];
  legalDisclaimer?: HtmlString;
}

const dummyPerilsList = [
  "Accidental Death",
  "Accidental Dismemberment",
  "Accidental Medical Expenses",
  "Accidental Repatriation",
  "Emergency Medical Evacuation",
  "Emergency Medical Reunion",
];

const InfoBanner: FC<InfoBannerProps> = ({
  promotionalDescription = "<p>This should be the promotional description.</p>",
  perils = dummyPerilsList,
  legalDisclaimer = "<p>This should be the legal disclaimer.</p>",
}) => {
  return (
    <Box bgcolor="#f2f2f2">
      <Container maxWidth="lg" sx={{paddingY: 4}}>
        <Grid container spacing={{xs: 2, md: 8}}>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography variant="h4" sx={{ mb: 2 }} fontFamily="Playfair Display">
              Your back-up plan.
            </Typography>
            <Typography
              variant="body1"
              fontFamily="Lato"
              dangerouslySetInnerHTML={{ __html: promotionalDescription ?? "" }}
            ></Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography sx={{ mb: 2 }} variant="h4" fontFamily="Playfair Display">
              Protects against
            </Typography>
            <Grid
              sx={{
                display: "flex",
                m: "auto",
              }}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {perils.length > 0 &&
                perils.map((p, i) => {
                  return (
                    <Grid
                      key={i}
                      item
                      xs={12}
                      sx={{ display: "flex", gap: 1, alignItems: "center" }}
                    >
                      <TaskAltIcon sx={{ color: "green" }} />
                      <Typography variant="body1" fontFamily="Lato">
                        {p}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default InfoBanner;
