import { Box, Container, Typography } from "@mui/material";
import { FC } from "react";
import { ProductType } from "../../core/util";

interface StockBannerProps {
  product: ProductType;
}

const bannerConfig: any = {
  'medsaver': {
    banner: '/stock-soccer-players.png',
    header: 'Accidents happen. Be prepared.',
    caption: 'Get up to $5,000 of individual accident medical coverage.'
  },
  'event-participation-cancellation-insurance': {
    banner: '/stock-basketball-players.jpg',
    header: 'For the unexpected',
    caption: 'Thank you for your interest in protecting your investment by purchasing cancellation insurance through Vertical Insure.'
  }
}



const StockBanner: FC<StockBannerProps> = ({product}) => {
  if (product in bannerConfig) {
    return (
      <Box
        sx={{
          backgroundImage:
            `linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(${bannerConfig[product].banner})`,
          backgroundSize: "cover"
        }}
      >
        <Container maxWidth="lg" sx={{ color: "white", py: 10, minHeight: {sm: 600, xs: 300}, display: 'flex', alignItems: 'flex-end' }}>
          <Box sx={{width: {sm: 800, xs: '100%'}}}>
            <Typography variant="h1" sx={{ mb: 4 }}  fontFamily="Playfair Display">
              {bannerConfig[product].header}
            </Typography>
            <Typography variant="body1" fontSize="1.45rem" fontFamily="Lato">
              {bannerConfig[product].caption}
            </Typography>
          </Box>
          
        </Container>
      </Box>
    );
  } 

  return null;
}

export default StockBanner;