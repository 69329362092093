import { FC } from "react";

import { Stack, Typography, ThemeProvider } from "@mui/material";

import theme from "../styles/theme";
import Appbar from "../components/Appbar";
import PartnerThemeResponse from "../core/partner";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

interface SuccessfulPurchaseProps {
  state?: {
    partnerTheme?: PartnerThemeResponse;
  }
}

const SuccessfulPurchase: FC<SuccessfulPurchaseProps> = () => {
  const {state} = useLocation();
  return (
    <ThemeProvider theme={theme(state?.partnerTheme)}>
      <Appbar partnerTheme={state?.partnerTheme}></Appbar>

      <Stack
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          fontFamily="Lato"
          sx={{
            p: 4,
            pb: 1,
            textAlign: "center",
            color: "secondary.main"
          }}
        >
          Thank you for your purchase.
        </Typography>

        <Typography
          variant="h6"
          fontFamily="Lato"
          sx={{
            p: 4,
            pt: 1,
            textAlign: "center",
          }}
        >
          A confirmation email has been sent to you.
        </Typography>
      </Stack>
      <Footer />
    </ThemeProvider>
  );
};
export default SuccessfulPurchase;
