import { Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { primaryColor } from "../styles/theme";

export default function Spinner() {
  return (
    <Box flex="1" display="flex" justifyContent="center" p={3}>
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
          }}
          size={50}
          thickness={3}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            animationDuration: "550ms",
            position: "absolute",
            color: primaryColor,
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={50}
          thickness={3}
        />
        <img
          src="/logo-spinner.png"
          style={{ position: "absolute", left: 0 }}
          width="50"
          height="50"
          alt=""
        />
      </Box>
    </Box>
  );
}
