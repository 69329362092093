export type Id = string;
export type Token = string;
export type ProductType =
  | "shipping"
  | "event-participation-cancellation-insurance"
  | "medsaver";

export const API_HOST = "https://api.verticalinsure.com";

/**
 * Format example: 2023-03-03T19:33:24.229693994Z
 */
export type FullDateString = string;

export type HtmlString = string;
export type UrlString = string;

export interface FormField {
  value: string;
  error: boolean;
  errorMsg: string;
}