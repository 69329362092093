import {
  AuthenticationError,
  PurchaseFailedError,
  PaymentFailedError,
  QuoteDatesInvalidError,
  QuoteNotAvailableError,
} from "../core/errors";
import PolicyAttributes from "../core/policy_attributes";
import { PurchaseRequest, PurchaseResponse } from "../core/purchase";
import { QuoteResponse } from "../core/quote";
import { API_HOST, Id, ProductType, Token } from "../core/util";

const handleQuoteResponse = (response: Response, quoteId: string) => {
  if (!response.ok) {
    const status = response.status;

    if (status === 422) throw new QuoteDatesInvalidError();
    else if (quoteId !== undefined && (status === 404 || status === 422))
      throw new QuoteNotAvailableError();
    else if (status === 401 || status === 403)
      throw new AuthenticationError();
    throw new Error(response.statusText);
  }
}

export default function useInsuranceApi<P extends PolicyAttributes>() {
  async function getQuote(
    productType: ProductType,
    quoteId: Id,
    authToken: Token
  ): Promise<QuoteResponse<P>> {
    const endpoint = `${API_HOST}/v1/quote/${productType}/${quoteId}?id=${authToken}`;

    const response = await fetch(endpoint, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-api-source": "Hosted Conversion"
      },
    });

    handleQuoteResponse(response, quoteId);
    return await response.json();
  }

  async function updateQuote(
    productType: ProductType,
    quoteRequest: any,
    clientId: string
  ): Promise<QuoteResponse<P>> {
    const endpoint = `${API_HOST}/v1/quote/${productType}`;

    const response = await fetch(endpoint, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-api-client-id": clientId,
        "x-api-source": "Hosted Conversion"
      },
      body: JSON.stringify(quoteRequest)
    });

    handleQuoteResponse(response, quoteRequest.quote_id);
    return await response.json();
  }

  async function purchaseQuote(
    productType: ProductType,
    quoteId: Id,
    authToken: Token,
    quote: QuoteResponse<P>,
    paymentMethodAsToken: Token
  ): Promise<PurchaseResponse<P>> {
    const endpoint = `${API_HOST}/v1/purchase/${productType}?id=${authToken}`;

    const purchaseRequestBody: PurchaseRequest<P> = {
      customer: quote.policy_holder,
      quote_id: quote.quote_id,
      policy_attributes: quote.policy_attributes,
      payment_method: { token: paymentMethodAsToken },
    };

    const response = await fetch(endpoint, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(purchaseRequestBody),
    });

    if (!response.ok) {
      const status = response.status;

      if (quoteId !== undefined && (status === 404 || status === 422))
        throw new PurchaseFailedError();
      if (status === 402) 
        throw new PaymentFailedError();
      if (status === 401 || status === 403) throw new AuthenticationError();
      throw new Error(response.statusText);
    }

    return await response.json();
  }

  return { getQuote, updateQuote, purchaseQuote };
}
