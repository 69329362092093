import { FC, useState, useEffect } from "react";
import {
  Divider,
  Toolbar,
  Link,
  Box,
  Typography,
  AppBar
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import PartnerThemeResponse from "../core/partner";

interface AppbarProps {
  partnerTheme?: PartnerThemeResponse;
}

const Appbar: FC<AppbarProps> = ({ partnerTheme = undefined }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up(740));
  const [darkTheme, setDarkTheme] = useState(false);

  useEffect(() => {
    document.querySelector("meta[name='theme-color']")?.setAttribute("content", partnerTheme?.color_primary || '#FFFFFF');

    // decide if the theme should be considered dark or light
    var color = (theme.palette.primary.main.charAt(0) === '#') ? theme.palette.primary.main.substring(1, 7) : theme.palette.primary.main;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    setDarkTheme(L <= 0.179);

  }, [partnerTheme]);

  const getVerticalInsureLogo = () => {
    return darkTheme ?
    'https://images.squarespace-cdn.com/content/v1/62fa76612310b76e8cd82853/42c1f70c-5732-4238-8168-aaed79b7107b/VI+Main+Logo-white.png?format=300w' :
    'https://images.squarespace-cdn.com/content/v1/62fa76612310b76e8cd82853/5757a4fb-39cc-484b-aeb4-4962d3c9d0ac/Vertical-Insure-blue.png?format=300w'
  }

  return (
    <AppBar
      sx={{ backgroundColor: theme.palette.primary.main, pb: 0.6, pt: 0.5, borderBottom: '1px solid #f2f2f2' }}
      position="static"
      elevation={0}
      
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ alignItems: "center" }}>
          {partnerTheme?.logo && (
              <>
                <Box
                  component="img"
                  sx={{ maxHeight: { xs: "55px", sm: "80px" }, p: 1.5 }}
                  display="flex"
                  alt="Partner Logo"
                  src={partnerTheme.logo}
                ></Box>
                <Divider sx={{ flexGrow: 1, borderColor: "transparent" }} />
              </>
          )}
          <Link 
            href="https://www.verticalinsure.com/about"
            sx={{ textDecoration: "none", color: "grey" }}
          >
            <Box
              component="img"
              alt="Vertical Insure Logo"
              display="flex"
              sx={{ maxHeight: { xs: "55px", sm: "80px"}, py: 1  }}
              src={getVerticalInsureLogo()}
            ></Box>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Appbar;

function AboutVerticalInsureLink() {
  return (
    <Link
      href="https://www.verticalinsure.com/about"
      sx={{ textDecoration: "none", color: "grey" }}
    >
      <Typography
        variant="subtitle1"
        fontFamily="Lato"
        sx={{ fontWeight: "bold" }}
      >
        ABOUT VERTICAL INSURE
      </Typography>
    </Link>
  );
}
