import {
  AuthenticationError,
  PartnerLogoNotAvailableError,
} from "../core/errors";
import PartnerThemeResponse from "../core/partner";
import { API_HOST, Id, Token } from "../core/util";

export default function usePartnerApi() {
  async function getPartnerTheme(
    partnerId: Id,
    authToken: Token
  ): Promise<PartnerThemeResponse> {
    const endpoint = `${API_HOST}/v1/partners/${partnerId}/theme?id=${authToken}`;

    const response = await fetch(endpoint, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const status = response.status;

      if (partnerId !== undefined && (status === 404 || status === 422))
        throw new PartnerLogoNotAvailableError();
      if (status === 401 || status === 403) throw new AuthenticationError();
      throw new Error(response.statusText);
    }

    return await response.json();
  }

  return { getPartnerTheme };
}
